<template>
  <v-sheet class="pa-3">
    <v-container>
      <v-row class="mb-5">
        <v-col>
          <v-btn icon small class="float-right" @click="$emit('closeDialog')"
            ><v-icon small>mdi-close</v-icon></v-btn
          >
          <h3>Add Journal Entry ({{ journal_entry_number }})</h3>
        </v-col>
      </v-row>
      <!--Validation alert-->
      <v-row v-if="general_journal_entry_validate_dialog">
        <v-col>
          <v-alert type="error" dense text
            >Please ensure that the entry has a date, and that all line items
            have been assigned ledgers and amounts.</v-alert
          >
        </v-col>
      </v-row>
      <!--FORM-->
      <v-form v-model="valid" ref="form">
        <v-row>
          <v-col cols="4" sm="4" md="4">
            <!--Reference Number-->
            <v-text-field
              v-model="reference_number"
              dense
              outlined
              label="Reference #"
              hint="Optional: Invoice, PO number, etc."
            >
            </v-text-field>
          </v-col>
          <v-col cols="4" sm="4" md="4">
            <!--Supplier Name-->
            <v-autocomplete
              v-model="supplier"
              :items="suppliers"
              item-text="supplier_name"
              return-object
              dense
              outlined
              label="Supplier"
            >
            </v-autocomplete>
          </v-col>
          <!--Date Picker-->
          <v-col cols="4" sm="4" md="4">
            <v-menu
              ref="menu"
              v-model="menu"
              :close-on-content-click="true"
              :return-value.sync="date"
              transition="scale-transition"
              min-width="290px"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                  :value="journal_entry_date"
                  label="Entry Date"
                  append-icon="mdi-calendar"
                  readonly
                  v-bind="attrs"
                  v-on="on"
                  dense
                  outlined
                  clearable
                  color="#3d2cdd"
                  :rules="dateRules"
                ></v-text-field>
              </template>
              <v-date-picker
                v-model="journal_entry_date"
                no-title
                scrollable
                color="#3d2cdd"
                show-adjacent-months
                :allowed-dates="
                  (date) => date <= new Date().toISOString().substr(0, 10)
                "
              >
                <v-spacer></v-spacer>
                <v-btn text color="#ce2458" @click="menu = false">
                  Cancel
                </v-btn>
                <v-btn text color="#33cc99" @click="$refs.menu.save(date)">
                  OK
                </v-btn>
              </v-date-picker>
            </v-menu>
          </v-col>
        </v-row>
        <v-row>
          <v-col>
            <v-divider></v-divider>
          </v-col>
        </v-row>
        <!--Entry Breakdown-->
        <v-row class="my-3">
          <v-col>
            <h3>Entry Breakdown</h3>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="5" sm="5" md="5">
            <h5>Ledger Account</h5>
          </v-col>
          <v-col cols="4" sm="4" md="4">
            <h5>Total Amount</h5>
          </v-col>
          <v-col cols="2" sm="2" md="2">
            <h5>VAT Rate</h5>
          </v-col>
        </v-row>
        <!--Line Items-->
        <v-form
          v-for="(item, index) in line_items"
          :key="index"
          :ref="`lineItemForm_${index}`"
          lazy-validation
        >
          <v-row>
            <v-col cols="4" sm="4" md="4">
              <!--Ledger Account-->
              <v-autocomplete
                v-model="item.ledger_account"
                :items="chart_of_accounts.filter((item) => !item.locked)"
                item-text="account_name"
                return-object
                dense
                outlined
                label="Ledger Account"
                :rules="ledgerRules"
                required
              >
              </v-autocomplete>
            </v-col>
            <v-col cols="3" sm="3" md="3">
              <!--Total Amount-->
              <v-text-field
                v-model.number="item.line_amount"
                type="number"
                dense
                outlined
                label="Total Amount"
                :rules="amountRules"
                required
              >
              </v-text-field>
            </v-col>
            <v-col cols="2" sm="2" md="2">
              <!--VAT Amount-->
              <v-text-field
                readonly
                disabled
                dense
                outlined
                label="VAT Amount"
                v-bind:value="calculateVATAmount[index]"
              >
              </v-text-field>
            </v-col>
            <v-col cols="2" sm="2" md="2">
              <v-select
                v-model="item.vat_rate"
                :items="[0, 15]"
                type="number"
                dense
                outlined
                label="VAT %"
                required
              >
              </v-select>
            </v-col>
            <!--Delete Button-->
            <v-col sm="1" md="1">
              <v-btn icon @click="removeItem(index)" :disabled="deleteDisable">
                <v-icon small>mdi-delete</v-icon>
              </v-btn>
            </v-col>
          </v-row>
          <!--Hidden Fields-->
          <v-row hidden>
            <v-col hidden>
              <!--Total Ex VAT-->
              <v-text-field
                hidden
                readonly
                v-bind:value="calculateTotalExVAT[index]"
              >
              </v-text-field>
            </v-col>
          </v-row>
        </v-form>
        <!--End of line items-->
        <!--Add more items to order-->
        <v-row class="mt-0">
          <v-col>
            <v-btn @click="addItems()" text color="#33cc99" dark>
              <v-icon left>mdi-plus</v-icon>
              Add Line Item
            </v-btn>
          </v-col>
        </v-row>
        <!---->
      </v-form>
      <!--End of FORM-->
      <!--Divider-->
      <v-row class="my-3">
        <v-col>
          <v-divider></v-divider>
        </v-col>
      </v-row>
      <v-row class="my-3">
        <v-col>
          <!--Cancel Button-->
          <v-btn text class="float-right" @click="$emit('closeDialog')"
            >Cancel</v-btn
          >
          <!--Submit Button-->
          <v-btn
            color="primary"
            class="float-right"
            @click="createJournalEntry()"
            ><v-icon left small>mdi-plus</v-icon> Add Journal Entry</v-btn
          >
        </v-col>
      </v-row>
    </v-container>
  </v-sheet>
</template>
<script>
import db from "../../../../components/firebaseInit";
import mixin_CompanyProfile from "../../../../globalActions/mixin_CompanyProfile";
import {
  getJournalEntryCount,
  getUser,
} from "../../data/external_journal_entries";
import { getSuppliers } from "../../../Banking/data/external_bank_statements";
import firebase from "firebase";
import {
  newDateToISO,
  firebaseTimestamp,
  firestoreDocProps,
} from "../../../../composables/external";
import { showSnackbar } from "../../../../globalActions/index";
export default {
  name: "AddJournalEntryModal",
  mixins: [mixin_CompanyProfile],
  data() {
    return {
      valid: false,
      loading: false,
      menu: null,
      date: null,
      journal_entry_id: null,
      journal_entry_number: null,
      reference_number: null,
      supplier: { supplier_name: null },
      journal_entry_date: null,
      deleteDisable: true,
      line_items: [
        {
          ledger_account: null,
          line_amount: 0,
          vat_rate: 0,
          vat_amount: null,
          entry_amount: null,
        },
      ],

      journal_entry_created_by_id: null,
      journal_entry_created_by_name: null,
      chart_of_accounts: [],
      suppliers: [],
      newSupplierDialog: false,
      general_journal_entry_validate_dialog: false,
      // Validation Rules
      dateRules: [(v) => !!v || "A date is required"],
      amountRules: [
        (v) => !!v || "An amount is required",
        (v) => v > 0 || "Amount must be greater than 0",
      ],
      ledgerRules: [(v) => !!v || "You must select a ledger"],
    };
  },
  computed: {
    determineVATRate() {
      return this.company_is_vattable ? 15 : 0;
    },
    calculateVATAmount() {
      const amount = this.line_items.map(
        (item) =>
          (item.vat_amount =
            (item.line_amount * item.vat_rate) / (100 + item.vat_rate))
      );
      return amount;
    },
    calculateTotalExVAT() {
      return this.line_items.map(
        (item) => (item.entry_amount = item.line_amount - item.vat_amount)
      );
    },
    calculateEntryTotal() {
      //  Total of all line items
      return this.line_items.reduce((total, item) => {
        return total + item.line_amount;
      }, 0);
    },
  },
  created() {
    this.getChartOfAccounts();
    this.getJournalEntryCount();
    this.getSuppliers();
    this.getUser();
  },
  methods: {
    getJournalEntryCount,
    getSuppliers,
    getUser,
    /*
        The ledger dropdown must be populated by "Accounting Totals"
        sub-collection docs. If we use the main "Chart Of Accounts" collection
        the user can select an account before the Cloud Function has triggered
        to add it to the sub-collection.
    */
    getChartOfAccounts() {
      const accountsRef = db
        .collection("accounting_totals")
        .doc(new Date(Date.now()).toISOString().slice(0, 7))
        .collection("ledger_accounts")
        .orderBy("account_name");
      accountsRef.onSnapshot((querySnapshot) => {
        this.chart_of_accounts = [];
        querySnapshot.forEach((doc) => {
          const data = {
            account_name: doc.data().account_name,
            account_id: doc.data().account_id,
          };
          this.chart_of_accounts.push(data);
        });
      });
    },
    //Adds a new empty line item to the order
    addItems() {
      this.line_items.push({
        ledger_account: null,
        line_amount: 0,
        vat_amount: 0,
        vat_rate: this.company_is_vattable ? 15 : 0,
      });
      this.deleteDisable = false;
    },
    //Deletes a specific line item from the order
    removeItem(index) {
      this.line_items.splice(index, 1);
      if (this.line_items.length === 1) {
        this.deleteDisable = true;
      }
    },
    //
    //  CREATE & RECONCILE JOURNAL ENTRY
    createJournalEntry() {
      // 1. Ensure form is validated before continuing
      const validateForm = () => {
        const validArray = [];
        validArray.push(this.$refs.form.validate());
        // Validate all variant items
        const evalLength =
          this.line_items.length === 1 ? 1 : this.line_items.length;
        for (let index = 0; index < evalLength; index++) {
          // When using :ref in a v-for results are returned as an array
          // instead of a single item. Use [0] to access the value we need
          validArray.push(this.$refs[`lineItemForm_${index}`][0].validate());
        }
        return (this.valid = !validArray.includes(false));
      };
      validateForm();
      if (!this.valid)
        return (this.general_journal_entry_validate_dialog = true);
      this.general_journal_entry_validate_dialog = false;
      //  2.  Save journal entry to database
      const saveJournalEntry = () => {
        this.loading = true;
        const entryRef = db.collection("general_journal_entries").doc();
        //    Create VAT entry
        createVATEntries();
        //    Create entry in firebase
        entryRef
          .set({
            ...firestoreDocProps(
              [
                "journal_entry_number",
                "reference_number",
                "supplier",
                "journal_entry_date",
                "line_items",
                "journal_entry_created_by_id",
                "journal_entry_created_by_name",
              ],
              this
            ),
            journal_entry_id: entryRef.id,
            journal_entry_date_created: newDateToISO(),
            journal_entry_timestamp: firebaseTimestamp(),
            closed_off: false,
          })
          .then(() => {
            this.loading = false;
            showSnackbar("New journal entry added & reconciled successfully");
          })
          .catch((err) => console.log(err));
      };
      //  3.  Create VAT Entries
      const createVATEntries = () => {
        // loop through all line items ()
        this.line_items.forEach((item) => {
          const data = {
            ledger_account: {
              account_code: "9010",
              account_id: "VQG2QlAbXMcjbWAeDwPK",
              account_name: "VAT Input",
            },
            entry_amount: item.vat_amount,
          };
          this.line_items.push(data);
        });
      };
      // 3. Increase GJE counter
      const increaseJournalEntryCounter = function () {
        const increment = firebase.firestore.FieldValue.increment(1);
        const increaseCount = firebase
          .firestore()
          .collection("general_journal_entry_counter")
          .doc("LVEzU1bhljJ8ZLLz6pC2");
        increaseCount
          .update({ journal_entry_count: increment })
          .then(() => {
            console.log("Counter incremented");
          })
          .catch((err) => console.log(err));
      };
      //  ACCOUNTING LOGIC
      //  4. Add entry amount of each line item to "Accounting Totals"
      const AddLineAmountsToAccountingTotals = () => {
        const batch = db.batch();
        this.line_items.forEach((item) => {
          //  Update line item amounts for each ledger in "Accounting Totals"
          const entryRef = db.doc(
            `accounting_totals/${this.journal_entry_date.slice(
              0,
              7
            )}/ledger_accounts/${item.ledger_account.account_id}`
          );
          batch.update(entryRef, {
            monthly_total: firebase.firestore.FieldValue.increment(
              item.entry_amount.toFixed(2)
            ),
          });
        });
        batch.commit().then(() => {
          console.log(`${this.journal_entry_date.slice(0, 7)} ledgers updated`);
          // Reset values so more entries can be created without having to reset the page
          const resetFieldValues = () => {
            this.valid = false;
            this.loading = false;
            this.menu = null;
            this.date = null;
            this.journal_entry_id = null;
            this.journal_entry_number = null;
            this.reference_number = null;
            this.supplier = { supplier_name: null };
            this.journal_entry_date = null;
            this.deleteDisable = true;
            this.line_items = [
              {
                ledger_account: null,
                line_amount: 0,
                vat_rate: 0,
                vat_amount: null,
                entry_amount: null,
              },
            ];
            this.journal_entry_created_by_id = null;
            this.journal_entry_created_by_name = null;
            this.chart_of_accounts = [];
            this.suppliers = [];
            this.general_journal_entry_validate_dialog = false;
          };
          resetFieldValues();
          // Close modal
          this.$emit("closeDialog");
        });
      };
      //  CALL FUNCTIONS
      saveJournalEntry();
      increaseJournalEntryCounter();
      //Add entry amount of each line item to "Accounting Totals"
      AddLineAmountsToAccountingTotals();
    },
  },
};
</script>